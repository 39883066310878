import config from '@/config';

/**
 * Teacher Response Question / Options
 */
export const getTeacherResponseQuestions = (state, getters) => (serviceId, group) => {
  const res = state.teacherResponseQuestions.filter(q => q.serviceId == serviceId && q.group == group);
  if (res.length == 0) { // use template questions
    const service = getters.getServiceById(serviceId);
    return state.teacherResponseQuestions.filter(q => q.group == group && q.anchorEventId == service.anchorEventId);
  }
  return res;
}

/**
 * Lessons
 */
export const getLessonByAnchorEventId = (state) => (anchorEvId) => {
  return state.lessons.find(l => l.id == anchorEvId) || {};
}

/**
 * University Students / jobEX
 */
export const activeJobEXes = (state) => {
  return state.allJobEXes.filter(jobEX => jobEX["Status"] == "Active");
}
export const userRelatedJobEX = (state) => {
  return state.allJobEXes.find(jobEX => jobEX.relatedProgramIds.includes(state.user.programId)) || {};
}
export const getJobEXByProgramId = (state) => (programId) => {
  return state.allJobEXes.find(jobEX => jobEX.relatedProgramIds.includes(programId));
}
export const jobEXProgrammes = (state) => {
  const programmes = [];
  for (const jobEX of activeJobEXes(state)) {
    for (let i = 0; i < jobEX.relatedProgramNames.length; i++) {
      programmes.push({
        name: jobEX.relatedProgramNames[i],
        id: jobEX.relatedProgramIds[i] || jobEX.relatedProgramIds[0],
      });
    }
  }
  return programmes;
}

export const getClientById = (state) => (id) => {
  return state.allClients.find(client => client.id == id);
}
export const userRelatedClient = (state) => {
  return state.allClients.find(client => client.id == state.user.clientId) || {};
}
export const allClientProgramIds = (state) => {
  const ids = [];
  for (const client of state.allClients) ids.push(...client.allRelatedProgramIds);
  return ids;
}


/**
 * My Credentials
 */
export const groupedUserCredentials = (state) => {
  const groupedObj = {};
  for (const credential of state.userCredentials) {
    const { section, headline } = credential;
    groupedObj[section] = groupedObj[section] || {};
    (groupedObj[section][headline] = groupedObj[section][headline] || []).push(credential);
  }
  for (const section in groupedObj) {
    for (const headline in groupedObj[section]) {
      groupedObj[section][headline].sort((a, b) => +a.order < +b.order ? -1 : (+a.order > +b.order ? 1 : 0));
    }
  }
  // should return array of sections instead of object (sort in order)
  return groupedObj;
}
export const orderedUserSections = (state) => {
  return state.userSections.sort((a, b) => a.order == "" ? 1 : (a.order < b.order ? -1 : 1));
}
export const suggestedSections = (state) => {
  const uniqueSections = {};
  for (const cred of state.userCredentials) uniqueSections[cred.section] = true;
  for (const section of state.myCredentialsSections) uniqueSections[section] = true;
  return Object.keys(uniqueSections);
}
export const myCredentialsHeadlines = (state) => {
  const uniqueHeadlines = {};
  for (const cred of state.userCredentials) uniqueHeadlines[cred.headline] = true;
  return Object.keys(uniqueHeadlines);
}

// jobEX Events (L1 / L2 / L3 / Consultation Session)
export const userJobEXIntakeSessions = (state, getters) => (anchorEventId = null) => {
  const { intakes } = getters.userRelatedJobEX;
  const relatedIntakeId = intakes && intakes.length > 0 ? intakes[0].id : null;
  const res = relatedIntakeId ? state.sessions.filter(ev => ev.group == "jobEX" && (!ev.jobEXIntakeId || ev.jobEXIntakeId == relatedIntakeId)) : [];
  return anchorEventId ? res.filter(s => s.anchorEventId == anchorEventId) : res;
 }

/**
 * Career Plan
 */
export const professionIdObjMapping = (state) => {
  const professionIdObjMapping = {};
  for (const profession of state.allProfessions) professionIdObjMapping[profession.id] = profession;
  return professionIdObjMapping;
}
export const getRelatedSegments = (state) => (sectorId) => {
  return state.allSegments.filter(segment => segment.sectorId == sectorId);
}
export const getSectorById = (state) => (sectorId) => {
  return state.allSectors.find(sector => sector.id == sectorId);
}
export const getSectorByName = (state) => (sectorName) => {
  sectorName = (sectorName || "").toLowerCase().trim();
  return state.allSectors.find(sector => sector.name.toLowerCase().trim() == sectorName);
}
export const getSectorBySegmentName = (state) => (segmentName) => {
  const segment = state.allSegments.find(segment => segment.name.toLowerCase().trim() == segmentName.toLowerCase().trim());
  return segment ? segment.sector : null;
}
export const getSegmentIdByName = (state) => (segmentName) => {
  const segment = segmentName ? state.allSegments.find(segment => segment.name == segmentName) : null;
  return segment ? segment.id : '';
}
export const professionGroupNames = (state) => {
  return [...new Set(state.allProfessions.map(p => p.professionGroupName))].filter(n => !!n);
}

/**
 * SLP
 */
export const getLastAppliedSessionByServiceId = (state) => (serviceId) => {
  const targetSessionIds = state.sessions.filter(ev => {
    return ["work-campus-visit", "work-workshop-2"].includes(ev.anchorEventId) && ev.serviceId == serviceId
            && (new Date(ev.startTimeStr) > new Date(+new Date() - 86400000));
  }).map(ev => ev.id);
  const appliedResponses = state.user.sessionResponses.filter(resp => targetSessionIds.includes(resp.sessionId) && resp.confirmed == 'Yes')
                                                    .sort((a, b) => (new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1));
  if (appliedResponses.length > 0) {
    return state.sessions.find(s => s.id == appliedResponses[0].sessionId);
  }
  return null;
}
export const getLastAttendedSession = (state) => {
  let attendedResponses = state.user.sessionResponses.filter(resp => resp.attended == 'Yes')
                                                    .sort((a, b) => (new Date(a.rollCallTime) > new Date(b.rollCallTime) ? -1 : 1));
  if (state.user.isAdmin) { // for admin check applied sessions for flexibility
    attendedResponses = state.user.sessionResponses.filter(resp => resp.confirmed == 'Yes').sort((a, b) => (new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1));
  }
  let latestWorkSession = null; // default today's Work session
  for (const resp of attendedResponses) {
    const session = state.sessions.find(s => s.id == resp.sessionId);
    if (session && session.group == 'Work') {
      latestWorkSession = session;
      break;
    }
  }
  return latestWorkSession || state.sessions.find(s => s.group == 'Work' && new Date(s.date).toDateString() == new Date().toDateString());
}

/**
 * Teacher
 */
export const getTeacherResponseByEventId = (state) => (eventId) => {
  if (state.user.teacherResponses) {
    return state.user.teacherResponses.find(resp => resp.sessionId == eventId);
  }
  return null;
}
export const getTeacherResponseByServiceId = (state) => (serviceId) => {
  if (state.user.teacherResponses) {
    return state.user.teacherResponses.find(resp => resp.serviceId == serviceId);
  }
  return null;
}

/**
 * ABS (Schools / Step 1,2,3 Questions / Intakes)
 */
export const userRelatedSchool = (state) => {
  const { user, schools } = state;
  const targetSchoolId = user && user.schoolId ? user.schoolId : "beacon1";
  return schools.find(s => s.id == targetSchoolId);
}
export const userSchoolAB4Intakes = (state) => {
  const { user, absIntakes } = state;
  const { schoolId } = user;
  return absIntakes.filter(t => t.schoolId == schoolId).sort((a,b) => (a.academicYear > b.academicYear ? -1 : 1));
}
export const userRelatedABSIntake = (state) => {
  const { user, absIntakes } = state;
  const { absIntakeId, schoolId } = user;
  return absIntakeId ? absIntakes.find(t => t.id == absIntakeId)
                    : absIntakes.find(t => t.schoolId == schoolId); // return latest intake if not linked yet
}
export const userABSIntakeSessions = (state, getters) => {
  //const relatedIntake = getters.userRelatedABSIntake;
  //return relatedIntake ? state.sessions.filter(ev => ev.group == "ABS" && ev.absIntakeId == relatedIntake.id) : [];
  return state.sessions.filter(ev => ev.group == "ABS" && ev.clientSchoolId == state.user.schoolId);
}
export const isAB4User = (state, getters) => {
  if (state.user.isAB4User == false) return false;
  return state.user.isAB4User || getters.userABSIntakeSessions.length > 0;
}
export const getStep1Questions = (state) => (fromAB3Modal, returnGroupedObj = true, specificGroups = null) => {
  const groupedObj = { "Passion": [], "Subject": [], "ECA": [], "Inspirer": [], "Worry": [] }
  const { user, step1Questions } = state;
  const targetSchoolId = user && user.schoolId ? user.schoolId : "beacon1"; // default beacon1
  let relatedStep1Questions = step1Questions.filter(q => q.schoolId == targetSchoolId);
  if (relatedStep1Questions.length == 0) relatedStep1Questions = step1Questions.filter(q => q.schoolId == "beacon1"); // if no question default use master question
  if (!returnGroupedObj) return relatedStep1Questions;

  for (const { group, options, order } of relatedStep1Questions) {
    let filteredOptions = options;
    if (!user.isAdmin) filteredOptions = filteredOptions.filter(opt => !opt.text?.includes('[Internal]'));
    const key = group || `Q${order}`;
    groupedObj[key] = groupedObj[key] || [];
    groupedObj[key] = [...groupedObj[key], ...filteredOptions];
  }
  if (specificGroups) {
    for (const group in groupedObj) {
      if (!specificGroups.includes(group)) {
        delete groupedObj[group]; // e.g. AB4 AI tool - skip ECA & Inspirer
      }
    }
  }
  //return groupedObj;
  //if (fromAB3Modal) return { "Strength": groupedObj["Subject"], "Worry": groupedObj["Worry"], "Passion": groupedObj["Passion"] }; 
  if (fromAB3Modal) return {}; // 20241009: Not show Passion tags (not safe), Subject tags (duplicate with AI)
  //delete groupedObj["Worry"]; // AB4 shows worry (more appropriate)
  return groupedObj;
  //return relatedStep1Questions.sort((a,b) => a.order - b.order);
}
export const getStep1OptionIds = (state, getters) => {
  const optionIds = [];
  const questions = getters.getStep1Questions(false, false);
  for (const q of questions) optionIds.push(...q.options.map(o => o.id));
  return [...new Set(optionIds)];
}
export const getUserRelatedStep1Questions = (state) => (isYear1, returnGroupedObj = false) => {
  const relatedStep1Questions = state.step1Questions.filter(q => q.programId == state.user.programId && q.options.length > 0);
  if (!returnGroupedObj) {
    return isYear1 ? relatedStep1Questions.filter(q => q.group == 'Year 1').map(q => ({ ...q, group: q.title }))
                  : relatedStep1Questions.filter(q => q.group != 'Year 1' && q.group != 'Course');
  }
  const groupedObj = {}
  for (const { group, options, order } of relatedStep1Questions) {
    const key = group || `Q${order}`;
    groupedObj[key] = groupedObj[key] || [];
    groupedObj[key] = [...groupedObj[key], ...options];
  }
  return groupedObj;
}
export const getUserRelatedStep1OptionIds = (state, getters) => (isYear1) => {
  const optionIds = [];
  const questions = getters.getUserRelatedStep1Questions(isYear1);
  for (const q of questions) optionIds.push(...q.options.map(o => o.id));
  return [...new Set(optionIds)];
}
export const getStep1OptionsByQuestionId = (state) => (questionId) => {
  const targetQuestion = state.step1Questions.find(q => q.id == questionId);
  return targetQuestion?.options || [];
}
export const getStep1OptionById = (state) => (optionId) => {
  for (const q of state.step1Questions) {
    const opt = q.options.find(o => o.id == optionId);
    if (opt) return opt;
  }
  return {};
}


/**
 * Electives
 */
export const shuffledElectives = (state) => {
  return state.allElectives.slice().map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
}

/**
 * Sessions
 */
export const getSessionById = (state) => (id) => {
  return state.sessions.find(ev => ev.id == id) || {};
}

/**
 * Work Events
 * 20230625: Added CamX Events as well (for Alumni interview)
 */
export const getWorkEventSessionsByClientId = (state) => (clientId) => {
  if (!clientId) return [];
  const sessions = state.sessions.filter(ev => ev.clientId == clientId && ['work-workshop-2', 'work-campus-visit'].includes(ev.anchorEventId))
                                  .sort((a, b) => new Date(a.startTimeStr) < new Date(b.startTimeStr) ? -1 : 1);
  return sessions
}

/**
 * Uni Events
 * 20230625: Added CamX Events as well (for Alumni interview)
 */
export const getUniEventSessionsByClientId = (state) => (clientId) => {
  if (!clientId) return [];
  const sessions = state.sessions.filter(ev => ev.clientId == clientId && ['workshop2', 'cc31fb5c1'].includes(ev.anchorEventId))
                                  .sort((a, b) => new Date(a.startTimeStr) < new Date(b.startTimeStr) ? -1 : 1);
  return sessions;
}

export const getSessionsByAnchorEventId = (state) => (anchorEvId) => {
  anchorEvId = Array.isArray(anchorEvId) ? anchorEvId : [anchorEvId];
  return state.sessions.filter(ev => anchorEvId.includes(ev.anchorEventId) && (new Date(ev.startTimeStr) > new Date(+new Date() - 6*3600*1000)));
}
export const workEvents = (state) => {
  const { isCamXApplicant } = state.user;
  const targetEvGrps = isCamXApplicant ? ["CamX", "Work"] : ["Work"];
  return state.sessions.filter(ev => ev.status != 'Proposed' && targetEvGrps.includes(ev.group));
}
export const todayAppliedWorkEvents = (state, getters) => {
  return getters.workEvents.filter(ev => {
    const { response, confirmed, attended } = ev.userResponse || {};
    return (response == "Yes" || confirmed == "Yes" || attended == "Yes") &&
            new Date(ev.startTimeStr).toDateString() == new Date().toDateString();
  });
}
export const upcomingWorkEvents = (state, getters) => {
  return getters.workEvents.filter(ev => {
    if (ev.serviceStatus == "Inactive") return false; // not allow applications
    return new Date(ev.startTimeStr) > new Date(+new Date() - 1*24*3600*1000) &&
          ['work-campus-visit', 'work-workshop-2', 'lms-scholarship'].includes(ev.anchorEventId);
          //['work-campus-visit', 'work-workshop-nomination', 'lms-scholarship'].includes(ev.anchorEventId);
  });
  //return getters.workEvents.filter(ev => (new Date(ev.startTimeStr) > new Date(+new Date() - 86400000)));
}
export const getRelatedEvents = (state, getters) => (eventId) => {
  const event = state.sessions.find(ev => ev.id == eventId);
  if (event == null) return [];
  if (event.group == "jobEX") {
    return getters.userJobEXIntakeSessions().filter(ev => (new Date(ev.startTimeStr) > new Date() && ev.id != event.id));
  }
  return getters.workEvents.filter(ev => {
    return ev.group == event.group && new Date(ev.startTimeStr) > new Date() && ev.serviceId == event.serviceId && ev.id != event.id && !ev.name.toLowerCase().includes("band a");
  });
}
export const getSLPSessionByServiceId = (state) => (serviceId) => {
  const res = state.sessions.filter(s => s.serviceId == serviceId && s.anchorEventId == 'work-campus-visit');
  return res[res.length-1]; // latest session
}
export const getApplicationSessionsByServiceId = (state) => (serviceId, targetAnchorEvId = 'work-workshop-nomination') => {
  const upcomingSessions = state.sessions.filter(ev => {
    if (['Proposed'].includes(ev.status)) return false;
    return !['c98cc077d', 'video'].includes(ev.anchorEventId) && ev.serviceId == serviceId && (new Date(ev.startTimeStr) > new Date(+new Date() - 30*24*3600*1000))
  });
  const res = upcomingSessions.filter(s => s.anchorEventId == targetAnchorEvId);
  return res.length > 0 ? res : upcomingSessions;
}
export const groupedUpcomingWorkEvents = (state, getters) => {
  const obj = { };
  for (const ev of getters.upcomingWorkEvents) {
    const groupKey = ev.serviceId || " Others";
    (obj[groupKey] = obj[groupKey] || []).push(ev);
  }
  return obj;
}
export const numOfAppliedWorkEvents = (state, getters) => {
  return getters.workEvents.filter(ev => {
    const { response, confirmed, attended } = ev.userResponse || {};
    return (response == "Yes" || confirmed == "Yes" || attended == "Yes");
  }).length;
}
export const getUpcomingEventsByGroup = (state) => (group) => {
  return state.sessions.filter(ev => ev.group == group && (new Date(ev.startTimeStr) > new Date(+new Date() - 86400000)));
}

// For Teacher View (Upcoming Sessions + Past Programs)
export const upcomingTeacherViewSessions = (state, getters) => {
  const ucircleEvents = state.sessions.filter(ev => {
    return new Date(ev.startTimeStr) > new Date(+new Date() - 1*24*3600*1000) && ev.anchorEventId == 'c3faecaac';
  });
  return getters.upcomingWorkEvents.concat(ucircleEvents);
}
export const pastWorkEvents = (state, getters) => {
  const serviceIds = {}; // only show 1 event for each service at most
  return getters.workEvents.filter(ev => {
    console.log(ev.serviceId);
    if (serviceIds[ev.serviceId]) return false; // only show once
    //if (["e4b0d113", "e01f1308"].includes(ev.id)) return true; // old data structure: work-workshop-nomination
    const res = new Date(ev.startTimeStr) < new Date() && ['work-campus-visit', 'work-workshop-2', 'lms-scholarship'].includes(ev.anchorEventId);
    if (res) serviceIds[ev.serviceId] = true;
    return res;
  }).sort((a, b) => (new Date(a.startTimeStr) > new Date(b.startTimeStr) ? -1 : 1)).slice(0, 10);
}

/**
 * Services
 */
export const getServiceById = (state) => (id) => {
  return state.services.find(s => s.id == id) || {};
}
export const getServicesByTypes = (state) => (types) => {
  let res = [];
  for (const type of types) res = res.concat(state.services.filter(s => s.type == type));
  return res.sort((a, b) => a.order - b.order);
}

export const getUniServicesByClientId = (state) => (id) => {
  return state.services.filter(s => s.clientId == id && s.type == "jobEX")
}

export const getSecSchoolServicesByClientId = (state) => (id) => {
  return state.services.filter(s => s.clientId == id && s.type == "Work")
}

/**
 * Program / Institutions
 */
export const getProgramsById = (state) => (id) => {
  return state.allPrograms.find(p => (p.id == id));
}
export const getProgramsByType = (state) => (programType, excludeJUPASCodes = []) => {
  return state.allPrograms.filter(p => (p.programType == programType && !excludeJUPASCodes.includes(p.jupasCode)));
}
export const getInstitutionsByProgramType = (state, getters) => (programType) => {
  //let programs = state.draftPrograms.concat(getters.getProgramsByType(programType));
  let programs = getters.getProgramsByType(programType);
  programs = programs.filter(p => [1,2,3,4,5,6,7,9,11,12].includes(Number(p.institutionId))); // only include specific institutions
  return [...new Set(programs.map(p => p.institutionId))].map(instId => (state.allInstitutions.find(inst => inst.id == instId)));
}
export const getProgramsByDisciplineId = (state) => (disciplineId, fromAB3 = false) => {
  let programs = state.allPrograms.filter(p => p.disciplines.some(d => d.id == disciplineId));
  if (fromAB3) programs = programs.filter(p => p.programType == 'jupas'); // AB3: only show JUPAS programs
  return programs;
}
export const getInstitutionsByDisciplineId = (state, getters) => (disciplineId, fromAB3 = false) => {
  const programs = getters.getProgramsByDisciplineId(disciplineId, fromAB3);
  return [...new Set(programs.map(p => p.institutionId))].map(instId => (state.allInstitutions.find(inst => inst.id == instId)));
}
export const programsSortedByJUPASCodes = (state, getters) => {
  return state.allPrograms.filter(p => p.status != 'Draft' && p.programType == 'jupas')
                          .sort((a, b) => a.jupasCode.localeCompare(b.jupasCode)); // skip hypothetical programs
}

/**
 * Professions
 */
export const professionsWithCv = (state) => {
  return state.allProfessions.filter(p => p.numOfCvBestExamples > 0)
                              .sort((a, b) => a.name.localeCompare(b.name));
}
export const userLikedProfessions = (state) => {
  return state.allProfessions.filter(p => p.likedByUser);
}
export const userRecentBrowsedProfessions = (state) => {
  return state.user.browsedProfessionIds.map(id => (state.allProfessions.find(p => p.id == id))).filter(p => !!p);
}
export const getProfessionById = (state) => (id) => {
  return state.allProfessions.find(p => p.id == id);
}
export const getProfessionByName = (state) => (professionName) => {
  return state.allProfessions.find(p => p.name?.toLowerCase().replace("(coming soon)", "").trim() == professionName.toLowerCase().trim());
}
export const getRelatedSegmentsByProfessionId = (state) => (professionId) => {
  const profession = state.allProfessions.find(p => p.id == professionId);
  return profession ? profession.relatedSegments : [];
}
export const shuffledProfessions = (state) => (checkUserResponse = true, uniGradProfessionsOnly = false) => {
  // if user already submitted profession responses, the related professions will be put to last
  let targetProfessions = state.allProfessions.slice();
  if (uniGradProfessionsOnly) {
    // skip HD / ASSO / Sec School professions (except professionals like Social Worker)
    targetProfessions = targetProfessions.filter(p => p.isProfessional || (p.requiresBachelor && !(p.requiresHigherDiplomaAndAssociate || p.requiresSecondarySchool)));
  }
  return targetProfessions.slice().map(value => ({
    value,
    sort: checkUserResponse ? (value.userResponse ? 1 : Math.random()) : (value.relatedDisciplines.length == 0 ? 1 : Math.random())
  })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
}
export const getProfessionsByDisciplineId = (state) => (disciplineId) => {
  return state.allProfessions.filter(p => p.relatedDisciplineIds.includes(disciplineId.toString()));
}

/**
 * Disciplines
 */
export const shuffledDisciplines = (state) => {
  return state.allDisciplines.slice().map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
}

/**
 * Sectors / Segments
 */
export const userLikedSectors = (state) => {
  return state.allSectors.filter(s => s.likedByUser);
}
export const userLikedSegments = (state) => {
  return state.allSegments.filter(s => s.likedByUser);
}
export const userRecentBrowsedSectors = (state) => {
  return state.user.browsedSectorIds.map(id => (state.allSectors.find(s => s.id == id))).filter(s => !!s);
}
export const userRecentBrowsedSegments = (state) => {
  return state.user.browsedSegmentIds.map(id => (state.allSegments.find(s => s.id == id))).filter(s => !!s);
}